




































































import { Component, Watch } from "vue-property-decorator";
import Actions from "@/components/Actions.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import Table from "@/components/Table.vue";
import EditButton from "@/components/Button/EditButton.vue";
import { namespace } from "vuex-class";
import { mixins } from "vue-class-component";
import ViewSwitchMixin from "@/mixins/ViewSwitchMixin";
import VehicleForm from "@/views/Vehicle/VehicleForm.vue";
import type { IVehicle } from "@/interfaces/IVehicle";
import VehicleView from "@/views/Vehicle/VehicleView.vue";
import ArchiveButton from "@/components/Button/ArchiveButton.vue";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import ArchiveModal from "@/components/Modal/ArchiveModal.vue";
import ModalMixin from "@/mixins/ModalMixin";
import CompanyMixin from "@/mixins/CompanyMixin";
import { EDIT } from "@/constants/FormType";

const VehicleModule = namespace("vehicle");
const VehicleFilterModule = namespace("vehicle/localFilter");

@Component({
  components: {
    FscSimpleCard,
    FscPageHeader,
    ArchiveButton,
    VehicleView,
    VehicleForm,
    Actions,
    Table,
    CreateButton,
    EditButton,
    ArchiveModal,
  },
})
export default class Vehicles extends mixins(ViewSwitchMixin, CompanyMixin, ModalMixin) {
  public name = "Vehicles";

  public fields = [
    {
      key: "brandAndModel",
      label: this.$t("user.last_name"),
      sortable: false,
    },
    {
      key: "licensePlate",
      label: this.$t("vehicles.mark"),
      sortable: false,
      tdClass: "licensePlate",
    },
    {
      key: "licenseClasses",
      label: this.$t("calendar.class"),
      sortable: false,
      tdClass: "licenseClasses",
    },
    {
      key: "instructors",
      label: this.$t("general.driving_instructor"),
      sortable: false,
    },
    {
      key: "transmission",
      label: this.$t("vehicles.transmission"),
      sortable: false,
    },
  ];

  @VehicleFilterModule.State("filter")
  public vehicleLocalFilter: any;

  @VehicleFilterModule.Mutation("SET_LOCAL_FILTER")
  public setVehicleLocalFilter!: (payload: Record<any, any>) => void;

  @VehicleModule.Action("findAll")
  public findAllVehicleAction: any;

  @VehicleModule.Action("create")
  public create: any;

  @VehicleModule.Getter("getIsLoading")
  public loading: any;

  @VehicleModule.Getter("getDataList")
  public items: any;

  @VehicleModule.Getter("getTotal")
  public totalRows: any;

  @VehicleModule.Action("findOne")
  public findOne: any;

  @VehicleModule.Getter("getDataItem")
  public item: any;

  @VehicleModule.Action("update")
  public update: any;

  @VehicleModule.Action("archive")
  public archive: any;

  @VehicleModule.Getter("getId")
  public responseId: any;

  @VehicleModule.Getter("getSuccess")
  public vehicleSuccess: any;

  @VehicleModule.Getter("getArchiveSuccess")
  public archiveSuccess: any;

  public vehicleId: any = null;

  public archived = false;

  public visibleSafetyCheckDate = false;

  public conditionClasses = ["C", "CE", "D", "DE", "D1E", "D1", "T"];

  public async mounted() {
    if (this.vehicleLocalFilter instanceof Object) {
      this.archived = this.vehicleLocalFilter.archived;
    }
    const vehicleId = this.$route?.params?.id ? Number(this.$route?.params?.id) : 0;
    if (vehicleId !== 0) {
      this.vehicleId = vehicleId;
      await this.findOne({
        id: vehicleId,
        resource: "vehicles",
      });
      this.formType = EDIT;
      this.openForm();
    }
  }

  public requestQuery(): void {
    this.findAllVehicleAction({
      resource: "vehicles",
      params: { archived: this.archived },
    });
  }

  public async onRowClicked(ctx: any): Promise<void> {
    this.openView();
    this.selectedRow = ctx;
    await this.findOne({
      id: ctx.id,
      resource: "vehicles",
    });
    if (this.item && this.item.licenseClasses) {
      const included = this.conditionClasses.some((licenseClass) => this.item.licenseClasses.includes(licenseClass));
      this.visibleSafetyCheckDate = included;
    }
  }

  public get dataItem(): any {
    return this.typeCreate() ? {} : this.item;
  }

  public async onSubmit(item: IVehicle): Promise<void> {
    if (this.typeCreate() && this.vehicleId === null) {
      await this.create({
        data: item,
        resource: "vehicles",
      });
      await this.findAllVehicleAction({
        resource: "vehicles",
        params: { archived: this.archived },
      });
      this.vehicleId = this.responseId;
    } else {
      const id = this.selectedRow ? this.selectedRow.id : this.vehicleId;
      await this.update({
        id: id,
        data: { id: id, ...item },
        resource: "vehicles",
      });
      await this.findAllVehicleAction({
        resource: "vehicles",
        params: { archived: this.archived },
      });
    }
  }

  public get archivedOption(): Array<any> {
    return [
      {
        text: this.$t("vehicles.active_vehicles"),
        value: false,
      },
      {
        text: this.$t("vehicles.archived_vehicles"),
        value: true,
      },
    ];
  }

  public onChangeArchived(): void {
    this.setVehicleLocalFilter({ archived: this.archived });
    this.findAllVehicleAction({
      resource: "vehicles",
      params: { archived: this.archived },
    });
  }

  public async onArchived(): Promise<void> {
    await this.archive({ archived: true, id: this.selectedRow.id });
    this.onChangeArchived();
  }

  public onAbortForm(): void {
    this.vehicleId = null;
    this.closeForm();
  }

  private showArchiveModal(): void {
    if (!this.selectedRow) {
      return;
    }
    this.showModal("vehicle-archive-modal");
  }

  @Watch("archiveSuccess")
  private onArchiveSuccess(success: boolean): void {
    if (success) {
      this.findAllVehicleAction({
        resource: "vehicles",
        params: { archived: this.archived },
      });
      this.closeView();
    }
  }

  @Watch("$route.params.id", { immediate: true })
  private async onRouteParamsChange(route: any): Promise<any> {
    if (route && route !== 0) {
      this.closeForm();
      this.closeView();
      this.vehicleId = route;
      await this.findOne({
        id: route,
        resource: "vehicles",
      });
      this.formType = EDIT;
      this.openForm();
    }
  }
}
