






import { Component, Vue } from "vue-property-decorator";

@Component
export default class Badge extends Vue {
  public name = "Badge";
}
