

























































































import { Component, Prop, Vue } from "vue-property-decorator";
import FormView from "@/components/FormView.vue";
import type { IVehicle } from "@/interfaces/IVehicle";
import BadgeView from "@/components/BadgeView.vue";

@Component({
  components: { BadgeView, FormView },
})
export default class VehicleView extends Vue {
  public name = "VehicleView";

  @Prop()
  public data!: IVehicle;

  @Prop()
  public visibleSafetyCheckDate!: any;

  public closeView(): void {
    this.$emit("close");
  }

  public get vehicle(): string {
    return this.data.brandAndModel;
  }

  public get seasonalClosureEndDate(): string {
    return this.data.seasonalClosureEndDate || "-";
  }

  public get seasonalClosureStartDate(): string {
    return this.data.seasonalClosureStartDate || "-";
  }

  public get generalInspectionDate(): string {
    return this.data.generalInspectionDate || "-";
  }

  public get safetyCheckDate(): string {
    return this.data.safetyCheckDate || "-";
  }
}
