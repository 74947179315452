



















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FormCard extends Vue {
  public name = "FormCard";

  @Prop({ type: String })
  public cardBodyClass!: any;

  public get hasHeaderSlot(): boolean {
    return !!this.$slots.header;
  }

  public get hasFooterSlot(): boolean {
    return !!this.$slots.footer;
  }
}
